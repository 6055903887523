import { useEffect, useRef, useState } from "react";
import Menu, { MobileMenu } from "./Menu";
// import Chat from "./Chat";
import HomePage from "./Home";
import TasksPage from "./Tasks";
// import InboxPage from "./Inbox";
import { useApp } from "context";
import ClaimsPage from "./Claims";
import ProfilePage from "./Profile";
import OrgPage from "./Organization";
import ContactsPage from "./Contacts";
import CalendarPage from "./Calendar";
import { BsCheck } from "react-icons/bs";
import { useWindowSize } from "react-use";
import SearchBar from "components/SearchBar";
import { useHistory } from "react-router-dom";
import { InlineModal } from "components/Modals";
import ImgTitleSub from "components/ImgTitleSub";
import { IoMenuOutline } from 'react-icons/io5';
import { ClaimSearch } from 'components/ClaimSearch';

const PHDashboard = () => {
  const history = useHistory();
  const { handleUser, handleTasks, searchParams, handleContacts, handlePayees, handleLossTypes } = useApp();
  const { width, height } = useWindowSize();
  const contentWidth = width > 991 ? width - 260 : width;
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    if (!searchParams.get("tab")) {
      searchParams.set("tab", "home");
      searchParams.delete("project");
      history.replace({ search: searchParams.toString() });
    }

    handleUser();
    handleTasks();
    handlePayees();
    handleContacts();
    handleLossTypes();
    //eslint-disable-next-line
  }, []);

  return (
    <div
      style={{ width: width, height: height, background: "#ffffff" }}
      className="js-as"
    >
      <Menu />
      <MobileMenu open={mobileMenu} setOpen={setMobileMenu} />
      <div
        style={{ width: contentWidth, height: height, position: "relative" }}
      >
        <Header setMobileMenu={setMobileMenu} />
        <OrgPage />
        <HomePage />
        <TasksPage />
        <ClaimsPage />
        <ProfilePage />
        <ContactsPage />
        <CalendarPage />
      </div>
    </div>
  );
};

export default PHDashboard;

const Header = ({ setMobileMenu }) => {
  const { setOrgTab, memberships, selectedMembership, selectedOrg, setMembership } =
    useApp();
  const modalBtnRef = useRef(null);
  const [modal, setModal] = useState(false);
  const orgSelected = selectedMembership?.id;

  const handleOrg = (membership) => {
    setModal(false);
    setMembership(membership);
  };

  const handleTabOrg = (tab) => {
    setModal(false);
    setOrgTab(tab);
  };

  return (
    <>
      <div
        style={{
          height: 80,
          width: '100%',
          cursor: "pointer",
        }}
        className="jb-ac ps-3 ps-lg-0 pe-3 pe-lg-5"
      >
        {orgSelected && <ClaimSearch placeholder="Search Claims" style={{ width: '100%' }} contStyle={{ width: 400 }} />}
        <div style={{ width: 50, flexShrink: 0, height: 44 }} className='je-ac d-lg-none' onClick={() => setMobileMenu(true)}>
          <IoMenuOutline style={{ fontSize: 32, color: '#0D72C5' }} />
        </div>

        {orgSelected && (
          <div className="je-ac d-none d-lg-flex" onClick={() => setModal(true)} ref={modalBtnRef}>
            <ImgTitleSub
              img={selectedOrg?.logo?.downloadUrl}
              title={selectedOrg?.name}
              sub={selectedMembership?.role}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
      </div>
      <InlineModal
        btnRef={modalBtnRef}
        open={modal}
        handleClose={() => setModal(false)}
      >
        <div
          className="brelly-content ps-3 pe-3 pt-2 pb-3"
          style={{ borderRadius: 6, width: 300 }}
        >
          <div className="dash-box-title">Companies</div>
          {memberships?.map((membership, key) => (
            <div
              key={key}
              className="jb-ac mt-3"
              style={{ cursor: "pointer" }}
              onClick={() => handleOrg(membership)}
            >
              <ImgTitleSub
                img={membership?.organization?.logo?.downloadUrl}
                title={membership?.organization?.name}
                sub={membership?.role}
              />
              {membership?.organization?.id === selectedOrg?.id && (
                <BsCheck style={{ color: "#0D72C5" }} />
              )}
            </div>
          ))}
        </div>
        <div
          className="brelly-content ps-3 pe-3 pt-2 pb-3 mt-3"
          style={{ borderRadius: 6, width: 300 }}
        >
          <ImgTitleSub
            img={selectedOrg?.logo?.downloadUrl}
            title={selectedOrg?.name}
            sub={selectedMembership?.role}
            className="mt-2"
            style={{ cursor: "pointer" }}
          />
          <div className="brelly-text-btn mt-4" onClick={() => handleTabOrg(1)}>
            Company Info
          </div>
          <div className="brelly-text-btn mt-2" onClick={() => handleTabOrg(3)}>
            Team
          </div>
          <div className="brelly-text-btn mt-2" onClick={() => handleTabOrg(4)}>
            Workflows
          </div>
        </div>
      </InlineModal>
    </>
  );
};

// <div className="brelly-text-btn mt-2" onClick={() => handleTabOrg(2)}>
//   Billing & Plans
// </div>
