// External Imports
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
// import { Intercom } from "@capacitor-community/intercom";

// Internal Imports
import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import CheckBox from "components/CheckBox";
import AuthPage from "components/AuthImage";
import { BrellyBtn } from "components/Button";

const Login = () => {
  const history = useHistory(); // @ts-expect-error TS(2339): Property 'setError' does not exist on type 'unknow... Remove this comment to see the full error message
  const { setError, setUser, setLoading, searchParams } = useApp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const code = searchParams.get("code");

  const handleLand = async () => {
    setLoading(true);
    history.push(`/onboarding?code=${code}`)
  }

  useEffect(() => {
    if (code) {
      handleLand();
    }
    // check and see if we have a saved value for email from a previos login
    let savedEmail = localStorage.getItem("brelly-remember"); // if so, set it as the email value

    if (savedEmail) {
      setEmail(JSON.parse(savedEmail) || "");
      setRememberMe(true);
    } // eslint-disable-next-line
  }, []);

  const handleLogin = async () => {
    // make sure email is not blank
    if (email?.length < 1) {
      setError("Email cannot be blank!"); // make sure password is not blank
    } else if (password?.length < 1) {
      setError("Password cannot be blank!");
    } else {
      // save email to localStorage for use in "remember me" functionality
      if (rememberMe) {
        localStorage.setItem("brelly-remember", JSON.stringify(email));
      } else {
        localStorage.removeItem("brelly-remember");
      }

      setLoading(true);
      try {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        const user = await brelly.auth.signin(email.toLowerCase(), password);
        if (user && user.id) {
          if (user?.memberships?.length > 0) {
            let m0id = user?.memberships[0]?.id;
            history.push(`/dash?tab=home&mid=${m0id}`);
          } else {
            history.push("/dash?tab=home");
          }
          setUser(user);
          setLoading(false);
        } else {
          setLoading(false);
          setError("Something went wrong, please try again!");
        }
      } catch (err) {
        setError(err.message)
        setLoading(false);
      }
    }
  };

  return (
    <AuthPage title="Sign In">
      <Input label="Email" value={email} onChange={setEmail} />
      <Input
        label="Password"
        value={password}
        onChange={setPassword}
        type="password"
      />
      <div className="jb-ac mt-2" style={{ width: '100%' }}>
        <div
          style={{ fontSize: 14, color: "black", fontWeight: 400 }}
          className="js-ac brelly-text-btn"
        >
          <CheckBox
            title="Remember Me"
            value={rememberMe}
            setValue={setRememberMe}
          />
        </div>
            
        <div
          onClick={() => history?.push("/request-password-code")}
          style={{ fontSize: 14 }}
          className="brelly-text-btn"
        >
          Forgot Password
        </div>
      </div>
      <BrellyBtn onClick={handleLogin} className="mt-3">
        Login
      </BrellyBtn>
      <div className="jc-ac">
        <div
          onClick={() => history.push("/register")}
          style={{ fontSize: 14 }}
          className="brelly-text-btn mt-3"
        >
          Sign Up
        </div>
      </div>
    </AuthPage>
  );
};

export default Login;
