import { useApp } from "context";
import brelly from "@brelly/sdk";
import { useMeasure } from 'react-use';
import { useState, useEffect } from "react";
import { IoChevronDownOutline } from "react-icons/io5";
import { WorkflowTaskItem, WorkflowStageItem, WorkflowItem } from 'items';
import { AddWFTaskModal, EditPhaseModal, AddPhaseModal, AddWorkflowModal, EditWorkflowModal } from 'modals';

export const OrgStagesSection = ({ orgTab }) => {
  const { workflows, selectedMembership } = useApp();
  const isSelected = orgTab === "4";
  const [addNewModal, setAddNewModal] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const canEdit = selectedMembership?.role === "ADMIN" || selectedMembership?.role === "OWNER";
  const sortedWorkflows = workflows?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt))

  return (
    <div
      style={{
        width: "100%",
        paddingTop: 22,
        display: isSelected ? "" : "none",
        height: '100%',
      }}
    >
      {!selectedWorkflow?.id && (
        <>
          <div
            className="dash-box-title jb-ac mb-3"
            style={{ color: "black" }}
          >
            Manage Workflows
            {canEdit && <div onClick={() => setAddNewModal(true)} className='brelly-text-btn'>Add New Workflow</div>}
          </div>
          {sortedWorkflows?.map((w) => <WorkflowItem key={w?.id} workflow={w} canEdit={canEdit} setSelectedWorkflow={setSelectedWorkflow} />)}
          {canEdit && <AddWorkflowModal open={addNewModal} handleClose={() => setAddNewModal(false)} />}
        </>
      )}
      {selectedWorkflow?.id && <SelectedWorkflow canEdit={canEdit} workflow={selectedWorkflow} setSelectedWorkflow={setSelectedWorkflow} />}
    </div>
  );
};

const SelectedWorkflow = ({ canEdit, workflow, setSelectedWorkflow }) => {
  const phases = workflow?.phases;
  const [addNew, setAddNew] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const sortedPhases = phases?.sort((a,b) => a?.order - b?.order);
  const [ref, { height }] = useMeasure();

  const handleWorkflow = async () => {
    try {
      let { data } = await brelly.workflows.get(workflow?.id);
      setSelectedWorkflow(data)
    } catch(err) {
      console.log(err.message);
    }
  }

  return(
    <>
      {!selectedStage && (
        <>
          <div
            className="dash-box-title jb-ac mb-3"
            style={{ color: "black" }}
          >
            <div className='js-ac'>
              <IoChevronDownOutline onClick={() => setSelectedWorkflow(null)} style={{ transform: 'rotate(90deg)', marginRight: 12 }} />
              {workflow?.name}
            </div>
            {canEdit && <div onClick={() => setEditModal(true)} className='brelly-text-btn'>Edit</div>}
          </div>

          <div
            style={{ width: "100%", color: "#64666B", background: "#F6F5F5", borderRadius: 10  }}
            className="p-3 mb-1"
            ref={ref}
          >
            <div className="" style={{ fontWeight: 600, width: "100%", color: "#64666B" }}>
              About Workflow
            </div>
            <div className='mt-2' style={{ maxHeight: 100, overflowY: 'auto' }}>{workflow?.description || "Click edit to add a description to this workflow"}</div>
          </div>

          <div className="jb-ac mt-2 ps-1 pe-1" style={{ fontWeight: 600, width: "100%", height: 38, color: "#64666B" }}>
            Wrokflow Phases
            {canEdit && <div onClick={() => setAddNew(true)} className='brelly-text-btn'>Add New</div>}
          </div>

          <div style={{ height: 400 - height, overflowY: 'auto' }}>
            {sortedPhases?.map((stage) => (
              <WorkflowStageItem stage={stage} key={stage?.id} stages={phases} setSelectedStage={setSelectedStage} canEdit={canEdit} />
            ))}

            {sortedPhases?.length === 0 && (
              <div className='jc-ac flex-col' style={{ height: 200 }}>
                <div>You don’t have any phases for this workflow yet.</div>
                {canEdit && <div onClick={() => setAddNew(true)} style={{ color: "#0D72C5", fontWeight: 600 }}>Click here to add a new one.</div>}
              </div>
            )}
          </div>
        </>
      )}

      <AddPhaseModal open={addNew} handleClose={() => setAddNew(false)} workflow={workflow} setSelectedWorkflow={setSelectedWorkflow} />
      <EditWorkflowModal open={editModal} handleClose={() => setEditModal(false)} workflow={workflow} setSelectedWorkflow={setSelectedWorkflow} />
      {selectedStage && <SelectedStage canEdit={canEdit} stage={selectedStage} setSelectedStage={setSelectedStage} handleWorkflow={handleWorkflow} />}
    </>
  )
}

const SelectedStage = ({ stage, canEdit, setSelectedStage, handleWorkflow }) => {
  const [stageDetails, setStageDetails] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const sortedTasks = stageDetails?.workflowTasks?.sort((a, b) => a.order - b.order)
  const [ref, { height }] = useMeasure();

  const handleStage = async (updateWorkflow = true) => {
    try {
      let { data } = await brelly.phases.get(stage?.id)
      setStageDetails(data);
      if (updateWorkflow) {
        handleWorkflow()
      }
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleStage(false)
    //eslint-disable-next-line
  }, [])

  return(
    <>
      <div
        className="dash-box-title jb-ac mb-3"
        style={{ color: "black" }}
      >
        <div className='js-ac'>
          <IoChevronDownOutline onClick={() => setSelectedStage(null)} style={{ transform: 'rotate(90deg)', marginRight: 12 }} />
          {stageDetails?.name}
        </div>
        {canEdit && <div onClick={() => setEditModal(true)} className='brelly-text-btn'>Edit</div>}
      </div>
      <div
        style={{ width: "100%", color: "#64666B", background: "#F6F5F5", borderRadius: 10  }}
        className="p-3 mb-1"
        ref={ref}
      >
        <div className="" style={{ fontWeight: 600, width: "100%", color: "#64666B" }}>
          About Phase
        </div>
        <div className='mt-2' style={{ maxHeight: 100, overflowY: 'auto' }}>{stageDetails?.description || "Click edit to add a description to this phase"}</div>
      </div>
      <div className="jb-ac mt-2 ps-1 pe-1" style={{ fontWeight: 600, width: "100%", height: 38, color: "#64666B" }}>
        Claim Phase Tasks
        {canEdit && <div onClick={() => setAddTaskModal(true)} className='brelly-text-btn'>Add New</div>}
      </div>

      <div style={{ height: 400 - height, overflowY: 'auto' }}>
        {sortedTasks?.map((t) => <WorkflowTaskItem t={t} key={t?.id} tasks={stageDetails?.workflowTasks} handleStage={handleStage} />)}

        {stageDetails?.workflowTasks?.length === 0 && (
          <div className='jc-ac flex-col' style={{ height: 200 }}>
            <div>You don’t have any task for this claim phase.</div>
            {canEdit && <div onClick={() => setAddTaskModal(true)} style={{ color: "#0D72C5", fontWeight: 600 }}>Click here to add a new one.</div>}
          </div>
        )}
      </div>
      <EditPhaseModal phase={stageDetails} open={editModal} handleClose={() => setEditModal(false)} setSelectedStage={setSelectedStage} stageDetails={stageDetails} handleStage={handleStage} />
      <AddWFTaskModal phase={stage} open={addTaskModal} handleClose={() => setAddTaskModal(false)} handleStage={handleStage} />
    </>
  )
}
